import * as React from "react";
import Landing from "../components/landing";
import backgroundImage from "../images/studios/DSC_6607.jpg";

export default function IndexPage() {
  return (
    <Landing
      title="Outsource Production Focus on Composition"
      subtitle="We can take care of recordings and much more"
      currentPage="/for-composers"
      backgroundImage={backgroundImage}
    />
  );
}
